.btn {
	background-color: transparent;
	border-radius: 7px;
	padding: 6px 30px;
	font-weight: 700;
	text-transform: uppercase;
	
	transition: all 300ms ease-in-out;
	
	
	&:after {
		@include cob;
		content: 'c';
		margin-left: 10px;
	}
	
	
	&-white {
		color: #fff;
		border-color: #fff;
		
		&:hover,
		&:focus {
			color: $color-green-light;
			border-color: $color-green-light;
		}
	}
	
	&-green {
		color: $color-green-dark;
		border-color: $color-green-dark;
		
		&:hover,
		&:focus {
			color: $color-green-light;
			border-color: $color-green-light;
		}
		
		&-light {
			color: #fff;
			background-color: $color-green-light;
			border-color: $color-green-light;
			
			&:hover,
			&:focus {
				color: #fff;
				background-color: transparent;
				border-color: #fff;
			}
		}
	}
	
	
	
	&-none {
		padding-left: 0;
		padding-right: 0;
		border: 0;
	}
}