@import url(https://fonts.googleapis.com/css?family=Oxygen:400,700&subset=latin,latin-ext);

@font-face {
  font-family: 'cob';
  src: url('#{$font}/cob.eot');
  src: url('#{$font}/cob.eot?#iefix') format('embedded-opentype'),
       url('#{$font}/cob.woff2') format('woff2'), 
       url('#{$font}/cob.woff') format('woff'), 
       url('#{$font}/cob.ttf')  format('truetype'), 
       url('#{$font}/cob.svg#cobbook') format('svg');
}

@mixin cob() {
	font-family: 'cob';
	font-size: 0.7em;
	text-transform: none;
	line-height: 1;
	display: inline-block;
}



body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin _lead {
	font-size: 24px;
	line-height: (36/24);
	font-weight: 400;
	@include media(xs) {
		font-size: 20px;
	}
	
}

.lead {
	@include _lead;
	margin-bottom: 40px;
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-weight: 700;
}


h1, .h1 {
	@include media(xs) {
		font-size: 36px;
	}
}
h2, .h2 {
	@include media(xs) {
		font-size: 30px;
	}
}
h3, .h3 {}
h4, .h4 {}
h5, .h5 {}
h6, .h6 {}



.row {
	> .item {
		@include media-up(sm) { border-left: 1px solid transparent; }
		
		+ .item {
			border-color: #fff;
		}
	}
}

a {
	color: $text-color;
	&:focus, &:hover {
		color: $color-green-dark;
	}
}

.text {
	&-col-2 {
		column-count: 2;
	}
}




.green {
	color:  $color-green-light;
}




.list-green {
	list-style: none;
	padding-left: 0;
	> li {
		&:before {
			content: '\2022';
			color: $color-green-light;
			margin-right: 0.5em;
		}
	}
}


.list-tags {
	> li > a {
		text-decoration: underline;
	}
}



html, body {
	width: 100%;
	height: 100%;
}

.error404 {
	background-image: url('#{$img}/404.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	padding-top: 16vh;
	
	.lead {
		margin-bottom: 5px;
		+ p {
			margin-bottom: 20px;
		}
	}
}




.svg {
	display: inline-block;
	vertical-align: middle;
}



