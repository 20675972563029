.section {}

/*
$color-green-low: #d2e6c8; 
$color-green-light: #a0d250;
$color-green-dark: #14783c; 


$color-grey-low: #f7f7f7;
$color-grey-light: #b4b4b4;
$color-grey-dark: #3c3c3c;
$color-grey-hi: #5a5a5a;

*/

.section-size {
	padding-top: 50px;
	padding-bottom: 100px;
	
	h2 {
		margin-bottom: 34px;
	}
}

.section-banner {
	min-height: 500px;
}

.box-banner {
	@include make-row();
	overflow: hidden;
	position: relative;
	
	min-height: 250px;
	.col-left & {
		min-height: 500px;
		@include media(xs) { 
			min-height: 350px;
		}
		@include media-up(sm) { border-right: 1px solid #fff; }
	}
	
	
	color: #fff;
	border-bottom: 1px solid #fff;
	
	
	.image {
		position: absolute;
		&:before {
			content: '';
			background-color: rgba( #000, 0.3 );
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			
			transition: all 300ms ease;
		}
		
		.col-left & {
			right: 0;
		}
		.col-right & {
			left: 0;
		}
	}
	
	.info {
		position: absolute;
		bottom: 2em;
				
		.col-left & {
			right: 4vw;
			padding-left: 30px;
		}
		.col-right & {
			left: 4vw;
			padding-right: 30px;
		}
		
		> h2 {
			margin-bottom: 5px;
		}
		
		> div {
			@include media-up(md) {
				@include _lead;
			}
		}
	}
	
	
	&:hover {
		.image {
			&:before {
				background-color: rgba( #000, 0.4 );
			}
		}
	}

}

.section-write-about {
	padding-top: 30px;
	padding-bottom: 30px;
	.box-write-about {
		text-align: center;
		padding: 50px 0;
		
		
		@include media(xs) {
			padding: 20px 0;
		}
		@include media(sm) {
			padding: 10px 0;
		}
		@include media(md) {
			padding: 30px 0;
		}
	}
}


.section-lead {
	background-color: $color-grey-low;
	
	.row-icons {
		margin-top: 40px;
		margin-bottom: 30px;
	}
}

.box-icon {
	text-align: center;
	
	@include media(xs) { margin-bottom: 50px; }
	
	
	.image {
		text-align: center;
		line-height: 60px;
	}
	
	.title {
		margin: 40px 0 10px;
		font-weight: 700;
	}
}

.box-num-icon {
	margin-bottom: 30px;
	&:first-child {
		@include media(xs) { margin-top: 40px; }
	}
	
	
	@include media-down(sm) { text-align: center; }
	
	.image,
	.description {
		display: inline-block;
		vertical-align: middle;
	}
	
	.image {
		
	}
	.description {
		@include media-up(md) { padding-left: 30px; }
		@include media(xs) { text-align: left; }
		
		br {
			@include media(sm) {
				display: none;
			}
		}
	}
}


.section-brand {
	background-color: $color-green-low;
	background-color: #dcf0c8;
}
.box-brand {
	position: relative;
	margin-left: -15px;
	margin-right: -15px;
	min-height: 350px;
	color: #fff;
	text-align: center;
	margin-top: 30px;
	
	@include media(xs) { margin-top: 50px; }
	
	display: flex;
	align-items: center;
	
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(#0a4b32, 0.4);
		z-index: 1;
		transition: all 300ms ease;
	}
	
	.wrapper {
		position: relative;
		z-index: 2;
		width: 100%;
	}

	.logo {
		position: absolute;
		z-index: 2;
		top: -25px;
		right: 63px;
	}
	
	
	&.brand {
		&-geosystem { background-image: url('#{$img}/1.jpg'); }
		&-geoborder { background-image: url('#{$img}/2.jpg'); }
	}
	
	
	
	h2 {
		margin-top: 0;
		margin-bottom: 10px;
	}
	.text {
		@include media-up(md) {
			@include _lead;
		}
		margin-bottom: 10px;
	}
	
	&:hover {
		&:after { background-color: rgba(#0a4b32, 0.5); }
	}
	
}








.section-realizations {
	background-color: $color-green-dark;
	color: #fff;
	
// 	border-bottom: 1px solid #fff;
	
// 	.home & {
		padding-bottom: 50px;
// 	}
	
	

	h2 {
		margin: 0;
		line-height: 100px;
	}
	
	.row-realizations {
		margin-bottom: 50px;
		border-style: solid;
		border-width: 1px 0;
		border-color: #fff;
	}
	
}
.section-filters {
	h1 {
		margin-bottom: 40px;
	}
}




.row-filters {
	margin-bottom: 40px;
	
	padding: 0;
	list-style: none;
	line-height: 60px;
	
	overflow: hidden;
	
	border-radius: 7px;
	
	background-color: $color-green-light;
	color: #fff;
	text-align: center;
	font-weight: 700;
	
	> li {
		@include make-sm-column(3);
		+ li {
			@include media-up(sm) { border-left: 1px solid #fff; }
		}
		
		> a {
			display: block;
			color: #fff;
			&:hover, &:focus {
				text-decoration: none;
			}
		}
		&.active {
			background-color: $color-green-dark;
		}
	}
}





.box-filters {
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	
	
	.title {
		text-transform: none;
		color: #b4b4b4;
		margin-bottom: 1.5em;
	}
	
	> a {
		display: inline-block;
		margin: 0 1em;
		color: $color-grey-hi;
	}
}



.box-realization {
	display: block;
	position: relative;
	@include make-row();
	color: #fff;
	overflow: hidden;
	border-bottom: 1px solid #fff;
	
	.title,
	.more {
		
	}
	
	.title {
		position: absolute;
		bottom: 0;
		
		min-height: 50px;
		width: 100%;
		
		background-color: $color-green-dark;
		font-weight: 700;
		
		display: flex;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 5px;
		padding-bottom: 5px;
		
		transition: all 200ms linear;
	}
	
	.more {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		
		display: flex;
		align-items: center;
		background-color: rgba( $color-green-dark, 0.8);
		
		padding: 30px;
		text-align: center;
		
		visibility: hidden;
		opacity: 0;
		
		transition: all 300ms linear;
		color: #fff;
		
		> div {
			width: 100%;
		}
	}
	
	
	
	
	&:hover,
	&:focus {
		
		.title {
			transform: translateY(100%);
		}
		
		.more {
			visibility: visible;
			opacity: 1;
		}
	}
	
	.video & {
		.image {
			&:before {
				content: '\f16a';
				@extend .fa;
				position: absolute;
				left: 0;
				right: 0;
				width: 100%;
				text-align: center;
				font-size: 80px;
				top: calc( 50% - 40px );
				transition: all 200ms ease-in-out;
			}
		}
		
		&:hover {
			.image {
				&:before {
					opacity: 0;
				}
			}
		}
	}
	
}

.realization-tags {
	text-transform: uppercase;
	
	&.list-inline {
		> li {
			margin-bottom: 5px;
			padding-left: 10px;
			padding-right: 10px;
			
			> a {
				color: $color-grey-hi;
				&:hover,
				&:focus {
					color: $color-green-dark;
				}
			}
			
			&.active {
				> a {
					color: $color-green-dark;
				}
			}
		}
	}
}

.realization-items {
	> .item {
		&.item-hide {
			display: none;
		}
	}
}





.section-news {
	
	.row-news {
		margin-top: 50px;
		margin-bottom: 30px;

	}
}

.box-news {
	
	margin-bottom: 70px;
	
	@include media(xs) {
		margin-bottom: 45px;
	}
	
	.image {
		@include make-row();
	}
	
	h3 {
		margin-top: 35px;
		margin-bottom: 5px;
		> a {
			color: $color-grey-hi;
		}
	}
	.content {
		a {
			color: $color-green-dark;
			font-weight: 700;
			
			&:hover {
				color: $color-green-light;
			}
		}
	}
}










.section-top {
	min-height: 350px;
	background-color: $color-green-low;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	
	.geoborder & {
		@include media(xs) { background-position: calc( 50% - 140px ) 50%; }
	}
	
	.geododatki & {
		@include media(xs) { background-position: calc( 50% - 180px ) 50%; }
	}
	
	color: #fff;
	
	position: relative;
	
	.title {
		position: absolute;
		bottom: 70px;
		margin-bottom: 4px;
		
		h1 {
			margin-top: 0;
			margin-bottom: 5px;
		}
		> div {
			@include _lead;
		}
		
	}
}


.section-description {
	
	padding-top: 30px;
	
	.geododatki & {
		padding-bottom: 20px;
	}
	
	.row-lead {
		.image {
			> img {
				width: 150px;
			}
		}
	}
	
	h2 {
		margin-top: 70px;
	}
	
	.row-icons {
		margin-top: 50px;
		
	}
}



.section-application {
	@extend .section-realizations;
	padding-bottom: 0;
}
.box-application { 
	@extend .box-realization;
	
}



.section-tags {
	@include media-up(sm) { padding-top: 60px; }
	padding-bottom: 50px;
	background-color: $color-green-dark;
	
	color: #fff;
	
	border-top: 1px solid #fff;
	
	a {
		color: #fff;
		&:hover {
			
		}
	}
	
	h3 {
		margin-top: 0;
		margin-bottom: 20px;
		
		@include media(xs) {
			margin-top: 50px;
		}
	}
	
	ul {
		> li {
			margin-bottom: 15px;
			text-transform: uppercase;
			font-weight: 700;
		}
	}
}



.section-product {
	margin-top: 40px;
	
	h1 {
		margin-top: 0;
		margin-bottom: 30px;
	}
	
	.text {
		margin-bottom: 2em;
	}
	.btns {
		margin-bottom: 2em;
	}
	
	
	.slider-produkt {
		.slides {
			> li {
				img {
					width: auto;
					display: block;
					margin: auto;
					max-width: 100%;
				}
				.reelx {

				}
			}
		}
	}
	
	
	#image-reel {
		width: auto !important;
		height: auto !important;
		.reel-preloader {
			background-color: transparent !important;
		}
	}
	
	
	.table-shipment {
		width: 100%;
	}
}


.box-product {
	border: 1px solid $color-green-light;
	
	border-radius: 0 0 8px 8px;
	overflow: hidden;
	margin-bottom: 30px;
	
	
	.image {
		line-height: 320px;
		text-align: center;
		> img {
			max-width: 100%;
		}
	}
	
	.title {
		background-color: $color-green-light;
		padding: 17px 21px 16px;
		font-size: 16px;
		color: #fff;
	}
	
	h3 {
		margin-top: 0;
		margin-bottom: 5px;
		> a {
			display: block;
			position: relative;
			color: #fff;
			&:after {
				content: 'c';
				@include cob;
				position: absolute;
				right: 0;
				top: 5px;
			}
		}
	}
	
	.more {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		
		background-color: rgba( $color-green-light, 0.85 );
		color: #fff;
		display: flex;
		align-items: center;
		
		padding: 30px 20px;

		visibility: hidden;
		opacity: 0;
		transition: all 200ms linear;
		
		&-wrap {
			position: relative;
		}
		
		> div {
			width: 100%;
		}
	}
	
	
	&:hover,
	&:focus {
		.more {
			visibility: visible;
			opacity: 1;
		}
	}
}

.nav-tabs {
	border-bottom: 0;
	> li {
		overflow: hidden;
		
		
		@include media(xs) {
			float: none;
			display: block;
			margin-bottom: 1px;
			border-radius: 0 !important;
		}
		
		
		&:first-child {
			border-radius: 10px 0 0 0;
		}
		&:last-child {
			border-radius: 0 10px 0 0;
		}
		
		> a {
			&, &:focus, &:hover {
				background-color: $color-grey-light;
				border-bottom-color: transparent;
				margin-right: 1px;
				border-color: transparent;
				border-radius: 0;
				padding: 6px 2em;
				@include media(sm) {
					padding-left: 1em;
					padding-right: 1em;
				}
				
				color: #fff;
				font-weight: 700;
			}
			
			&.warranty {
				background-color: $color-green-dark;
			}
		}
		
		&.active {
			> a {
				&, &:focus, &:hover {
					border-color: transparent;
					background-color: $color-green-light;
					color: #fff;
				}
			}
		}
	}
}


.tab-content {
	border: 1px solid $color-grey-light;
	padding: 20px 15px 20px;
	strong {
		color: $color-green-dark;
		font-weight: 400;
	}
}

.flex-control-thumbs img {
	border: 1px solid $color-grey-light;
	padding: 10px;
	transition: all 100ms ease;
	
	
	&.flex-active {
		border-width: 2px;
		border-color: $color-green-light;
	}
}



.banner-extras {
	height: 300px;
	background-image: url('#{$img}/grass.jpg');
	background-size: cover;
	background-position: center;
	color: #fff;
	padding-top: 80px;
	
	.btn {
		margin-top: 10px;
	}
}






.section-maps {
	height: 350px;
}


.section-contact {
	
	h3 {
		&:first-of-type {
			margin-top: 0;
		}
	}
	
	.green {
		@include _lead;
		font-weight: 700;
		&:hover {
			text-decoration: none;
			color: $color-green-light;
		}
	}
	
	
	.ico-mail,
	.ico-phone {
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 23px;
			height: 23px;
			margin-right: 0.5em;
		}
	}
	.ico-phone:before {
		background-image: url('#{$img}/contact-green-phone.svg');
	}
	.ico-mail:before {
		background-image: url('#{$img}/contact-green-mail.svg');
	}
}


.section-people {
	background-color: $color-grey-low;
	
	a {
		color: #fff;
	}
}


.box-person {
	margin-bottom: 10px;
	background-color: #fff;
	
	
	.photo {
		position: relative;
	}
	
	.position {
		position: absolute;
		bottom: 0;

		width: 100%;
		background-color: $color-green-dark;
		color: #fff;
		font-weight: 700;
		padding: 5px 10px;
	}
	
	.content {
		
		h3 {
			@include media-up(md) {	
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}
	
	
	
	
	.contact {
		background-color: $color-green-dark;
		color: #fff;
		position: relative;
		
		.icos {
			position: absolute;
			top: 0;
			right: 0;
			width: auto;
			background-color: $color-green-light;
				padding: 0 8px;
			> img {
				+ img {
					margin-left: 7px;
				}
			}
		}
		
		@include media-up(md) {	text-align: center; }
		@include media-down(sm) { padding: 0 10px 5px; }
		font-weight: 700;
		
		@include media(md) {
			font-size: 0.85em;
		}
		@include media(sm) {
			font-size: 0.85em;
		}
	}
	
	.contact,
	.content {
		

		@include media-up(md) {
			min-height: 204px;	
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			> div {
				width: 100%;
			}
		}
		@include media-up(lg) {	
			min-height: 251px;
		}
	}
	
}





.section-advantages {
	background-color: $color-grey-low;
}
.box-advantage {
	margin-bottom: 40px;
	
	@include media-up(sm) {
		> .row {
			display: flex;
			align-items: center;
		}
	}
	
	.name {
		text-transform: uppercase;
		font-weight: 700;
		@include media(xs) {
			text-align: center;
		}
	}
	
	h3 {
// 		margin-top: 0;
		margin-bottom: 7px;
	}
}





.section-archive-products {
	
}


.box-archive-product {
	margin-bottom: 10px;
	
	overflow: hidden;
	
	
	&, .fake-height {
		min-height: 400px;
		
		&.text-list {
			@include media(xs) {
				min-height: 1px !important;
			}
		}
	}
	
	.text-list {
		@include media(xs) {
			padding-bottom: 70px;
		}
	}
	
	&:first-of-type {
		&, .fake-height {
			min-height: 500px;
		}
	}
	
	
	background-color: $color-green-dark;
	color: #fff;



	.image {
		
		position: absolute;
		right: 0;
		
		&:before {
			content: '';
			background-color: rgba( #000, 0.3 );
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		> img {

		}
	}
	
	.description {
		padding-right: 60px;
		font-size: $font-size-h3;
	}
	
	
	.description, {
			position: absolute;
			bottom: 70px;
			@include media(xs) {
				bottom: 20px
			}
			
			> h2 {
				margin-bottom: 10px;
			}
			
			> div {
				@include _lead;
				
			}
	}
	
	.btn {
		@include media-up(sm) {
			position: absolute;
			bottom: 70px;
		}
	}
	
	
	.lists {
		padding-top: 70px;
		@include media(sm) { font-size: 17px; }
		
		

	}
	
	.list-advantages {
		font-weight: 700;
		
		> li {
			margin-bottom: 10px;
			
			> img {
				@include media(xs) {
					width: 40px;
				}
			}
			
			> span {
				display: inline-block;
				vertical-align: middle;
				margin-left: 25px;
				@include media(sm) { margin-left: 15px; }
				@include media(xs) {
					margin-left: 15px;
					font-size: 0.9em;
				}
				
			}
		}
	}
	
}



.section-faq {}

.box-question {
	.question {
		@extend .lead;
		position: relative;
		display: block;
		font-weight: 700;
		padding-right: 30px;
		color: $color-grey-hi;
		
		&:after {
			content: 'e';
			@include cob;
			position: absolute;
			right: 0;
			top: 10px;
			color: $color-green-dark;
		}
		
		&.collapsed {
			&:after {
				content: 'c';
			}
		}
	}
	.answer {}
	
	hr {
		margin-left: -15px;
		margin-right: -15px;
		border-color: $color-grey-light;
	}
}



.section-realization {
	
	padding-bottom: 80px;
	padding-top: 100px;
	
	h1, h2, h3 {
		margin-top: 0;
	}
	
	h1 {
		margin-bottom: 30px;
	}
	
	.row-image {
		margin-bottom: 80px;
	}
	
	
	.row-more {
		margin-top: 80px;
	}
	
	.video {
		iframe {
			width: 100%;
			height: 310px;
		}
	}

}



.section-faq {
	padding-top: 100px;
	padding-bottom: 100px;
}


.progress {
	background-color: $color-grey-light;
	height: 15px;
	margin-bottom: 5px;
	box-shadow: none;
	
	.progress-bar {
		background-color: $color-green-light;
		box-shadow: none;
	}
}



