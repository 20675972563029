@mixin media( $point ) {
	
	@if ( $point == lg ) {
		@media (min-width: $screen-lg-min) { @content }
	}
	
	@else if ( $point == md ) {
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content }
	}
	
	@else if ( $point == sm ) {
		@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content }
	}
	
	@else if ( $point == xs ) {
		@media (max-width: $screen-xs-max) { @content }
	}
}

@mixin media-up( $point ) {
	
	@if ( $point == lg ) {
		@media (min-width: $screen-lg-min) { @content }
	}
	
	@else if ( $point == md ) {
		@media (min-width: $screen-md-min) { @content }
	}
	
	@else if ( $point == sm ) {
		@media (min-width: $screen-sm-min) { @content }
	}
	
	@else if ( $point == xs ) {
		@content
	}
}

@mixin media-down( $point ) {
	
	@if ( $point == lg ) {
		@content
	}
	
	@else if ( $point == md ) {
		@media (max-width: $screen-md-max) { @content }
	}
	
	@else if ( $point == sm ) {
		@media (max-width: $screen-sm-max) { @content }
	}
	
	@else if ( $point == xs ) {
		@media (max-width: $screen-xs-max) { @content }
	}
}


