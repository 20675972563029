.form-control {
	border-radius: 7px;
	height: 50px;
	margin-bottom: 5px;
	box-shadow: none;
	border-color: $color-grey-light;
	background-color: transparent;
	color: #296f30;
	
	&.white {
		border-color: #fff;
		&::-webkit-input-placeholder {
		   color: #fff;
		}
		
		&:-moz-placeholder {
		   color: #fff;  
		}
		
		&::-moz-placeholder {
		   color: #fff;  
		}
		
		&:-ms-input-placeholder {  
		   color: #fff;  
		}
	}
	
	&:focus {
		box-shadow: none;
		border-color: $color-green-dark;
	}
}