.section-newsletter {
	margin-top: 1px;
	background-color: $color-green-light;
	padding-top: 60px;
	padding-bottom: 60px;
	color: #fff;
	
	h2 {
		margin-top: 0;
	}
	.btn {
		&:hover {
			color: $color-green-dark;
			border-color: $color-green-dark;
		}
	}
	
}

.section-shop {
	background-color: $color-green-low;
	background-image: url('#{$img}/shop-bg.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 400px;
	margin-top: 1px;
	color: #fff;
	@include media(xs) {
		padding-bottom: 30px;
	}
	
	h2 {
		letter-spacing: -0.01em;
		@include media-up(md) {
			margin-top: 75px;
		}
		@include media(sm) {
			margin-top: 40px;
		}
// 		margin-bottom: 40px;
	}
	
	.image {
		position: absolute;
		right: 0;
		top: -60px;
	}
	
	.btn {
		margin-top: 1em;
	}
	
	.shop-links {
		@include media-up(md) { 
			margin-top: 6.8em;
			padding-left: 1em;
		}
		
		
	}
}

.foo-shop-link {
	display: block;
	@include media(sm) {
		display: inline-block;
	}
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	
	@include media-down(sm) {
		font-size: 0.85em;
	}
	
	@include media-up(md) {
		margin-bottom: 2.5em;
	}
	
	> span {
		display: inline-block;
		vertical-align: middle;
		margin-left: 2em;
		@include media(md) { margin-left: 1em; }
	}
	
	+ .foo-shop-link {
		@include media(sm) { margin-left: 2em; }
		@include media(xs) { margin-top: 1em; }
	}
	
	.svg path {
		fill: #FFFFFF;
	}
	
	&:hover,
	&:focus {
		color: $color-green-light;
		
		.svg path {
			fill: $color-green-light;
		}
	}
}


.footer {
	font-size: 14px;
	
	.navigation {
		min-height: 310px;
		padding-top: 60px;
		
		.logo {
			@include media-up(sm) {
				@include center-block;
			}
			@include media(xs) {
				margin-bottom: 20px;	
			}
		}
		
		
		.footer-menu {
			font-weight: 700;
			text-transform: uppercase;
			
			@include media(xs) {
				margin-top: 40px;
			}
			margin-bottom: 40px;
			
			> li {
				> a {
					color: $color-grey-hi;
				}
			}
		}
		
		.socialmendia {
			font-size: 24px;
			margin-top: 5px;
			margin-bottom: 30px;
			
			
			> li {
				> a {
					color: #b4b4b4;
					display: inline-block;
					padding-left: 0.3em;
					padding-right: 0.3em;
					&:hover, &:focus {
						color: $color-grey-hi;
					}
				}
			}
		}
		
		.ekokratka {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			margin-top: 5px;
			margin-bottom: 60px;
			
			
			.fa {
				font-size: 24px;
				margin-right: 0.5em;
			}
			
			> a {
				color: $color-green-dark;
			}
		}
	}
	
	.copyright {
		background-color: $color-grey-dark;
		color: #fff;
		font-size: 12px;
		line-height: 35px;
	}
}