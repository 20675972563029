// Glyphicons font path
$icon-font-path:        "../fonts/";
$font:	"../fonts";
$img:	"../images";

$fa-font-path: "../../bower_components/font-awesome/fonts";

// zielony
$color-green-low: #d2e6c8; 
$color-green-light: #a0d250;
// $color-green-dark: #14783c; 
$color-green-dark: #5cc24f; // #46aa50;  

// szary
$color-grey-low: #f7f7f7;
$color-grey-light: #dcdcdc; // #b4b4b4;
$color-grey-dark: #3c3c3c;
$color-grey-hi: #5a5a5a;


// Colors
// $brand-primary:         #27ae60;
$text-color: $color-grey-hi;
$link-color: #000;


$font-size-base: 18px;

$font-size-h1: 40px;
$font-size-h2: 36px;
$font-size-h3: 24px;
$font-size-h4: 18px;


$font-family-base: 'Oxygen', sans-serif;
$line-height-base: (30 / 18);


$link-hover-decoration: none;

/*


TEKST:

nagłówki:
H1: 40 pkt Oxygen Bold, interlinia 45 pkt
H2: 36 pkt Oxygen Bold
H3: 24 pkt Oxygen Bold, interlinia 36 pkt
H4: 18 pkt Oxygen Bold, interlinia 30 pkt, Wersaliki (przy opisach ikonach)

tekst ciągły:
Tekst podstawowy: 18 pkt Oxygen Regular, interlinia 30 pkt (wyjątek interlinia 36)
Tekst lead: 24pkt Oxygen Regular, interlinia 36 pkt
Tekst podstawowy mniejszy: 16 pkt Oxygen Regular, interlinia 24 pkt

*/
