.header-menu-lang {
	background-color: $color-grey-dark;
	color: #fff;
	font-size: 14px;
	ul {
		list-style-type: none;
		display: inline-block;
		
		> li {
			display: inline-block;
			padding: 0px 5px;
			&:hover, &:focus {
				color: $color-green-light;
				cursor: pointer;
			}
		}
		
	}
	.single-line {
		border-right: 1px solid #fff;
	}

}

.box-header {
	font-weight: 700;
	
	> div {
		display: inline-block;
	}
	
	
	.language-chooser {
		margin-bottom: 0;
		> li {
			+ li {
				border-left: 1px solid #fff;
				padding-left: 8px;
			}
			
			> a {
				color: #fff;
				text-transform: uppercase;

			}
			
			&.active > a { 
				color: $color-green-light;
			}
		}
	}
	
	.menu-shop {
		display: inline-block;
		background-color: $color-green-light;
		padding: 10px 30px;
		@include media(xs) { padding: 10px; }
		color: #fff;
		text-transform: uppercase;
		>img {	vertical-align: top;}
		>span {
			margin-right: 0.4em;
		}
	}
	
	.menu-faq {
		color: #fff;
		margin-left: 5px;
		margin-right: 10px;
	}
}



.navbar-custom {
	background-color: #fff;
	border: 0px;
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	padding-top: 30px;
	border-bottom: 1px solid #c8c8c8;
	
	.navbar-nav {
		@include media(xs) {
			text-align: right;
		}
		
		> li {
			> a {
				color: $color-grey-hi;
				padding-bottom: 8px;
				border-bottom: 3px solid transparent;
				&:hover,
				&:focus {
					background-color: transparent;
					@include media-up(sm) { border-bottom-color: $color-green-light; }
				}
			}
			
			&.active, &.current_page_parent {
				a {
					@include media-up(sm) { border-bottom-color: $color-green-light; }
				}
			}
		}
	}
	
	.navbar-brand {
		position: absolute;
		top: -45px;
		z-index: 1;
		
		img {
			width: 118px;
			height: 193px;
			
            filter: drop-shadow( 0px 0px 1px $color-grey-light );
            @include media(xs) {
	            width: 85px;
            }
		}
		
	}
}

.dropdown {
	
	
@include media-up( sm ) {
	&:hover {
		> .dropdown-menu {
			display: block;	
		}
	}
}

@include media( xs ) {
	.caret {
		display: none;
	}
}

}


.dropdown-menu {
	padding: 0;
	border: 0;
	border-radius: 0 0 7px 7px;
	overflow: hidden;
	font-size: 16px;

	
	
	@include media(xs) {
		text-align: right;
	}
	
	> li {
		+ li {
			border-top: 1px solid #dcdcdc;
			@include media(xs) { border: 0; }
		}
		
		> a {
			text-transform: none;
			font-weight: 700;
			color: #5a5a5a;	
			
			@include media-up(sm) {
				padding: 10px 20px;
				min-width: 200px;
			}
			
			&:focus, &:hover {
				color: #fff;
				background-color: $color-green-light;
			}
		}
		
		&.active {
			> a {
				&, &:focus, &:hover {
					color: #fff;
					background-color: $color-green-light;
				}
			}
		}
	}
}



.navbar-toggle {
	.icon-bar {
		background-color: $color-grey-dark;
	}
}





